import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "@kgui/core";
import { Box, Typography, Button } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ViewError = (props: {
  message: ReactNode;
  hideButton?: boolean;
}) => {
  const { webId } = useParams();
  const nav = useNavigate();

  return (
    <Container maxWidth="post">
      <Box textAlign={"center"} py={6}>
        <FontAwesomeIcon icon={["fad", "cactus"]} size="10x" color="green" />
        <Typography
          mt={3}
          variant="h3"
          align="center"
          color="textSecondary"
          paragraph
        >
          {props.message}
        </Typography>
        {props.hideButton !== true && (
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={["fad", "chevron-left"]} />}
            onClick={() => nav(`/v/${webId}`)}
          >
            Home
          </Button>
        )}
      </Box>
    </Container>
  );
};
