import { MainContainer } from "@kgui/core";
import { Link, Stack } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MenuTypes, WebController } from "../../controllers/web.fb";

interface ViewWrapperProps {
  children?: React.ReactNode;
}
export const ViewWrapper = (props: ViewWrapperProps) => {
  const { webId } = useParams();
  const [menu, setMenu] = React.useState<MenuTypes[]>([]);
  const nav = useNavigate();

  React.useEffect(() => {
    if (webId) {
      const control = new WebController(null);
      control.view.menu(webId).then((menu) => setMenu(menu));
    }
  }, [webId]);

  return (
    <MainContainer
      dense
      startActions={
        <Stack direction={"row"} spacing={2}>
          {menu.map((item) => (
            <Link
              variant="body1"
              color={"textPrimary"}
              fontWeight={"bold"}
              key={item.id}
              onClick={() => nav(`/v/${webId}/page/${item.id}`)}
              sx={{
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {item.title}
            </Link>
          ))}
        </Stack>
      }
    >
      {props.children}
    </MainContainer>
  );
};
