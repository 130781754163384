import { PageDocument, PageViewer } from "@kgui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {ViewError} from "../../components/view.error";
import { WebController } from "../../controllers/web.fb";

export const PageViewHome = () => {
  const { webId } = useParams();
  const [data, setData] = useState<PageDocument>({});
  const [error,setError] = useState<{[key:string]:boolean}>({})

  useEffect(() => {
    if (webId) {
      const control = new WebController(null);
      control.view.home(webId).then((data) => setData(data)).catch(err => {
        if(err.message==="menu empty"){
          setError({ menuEmpty:true })
        }
      });
    }
  }, [webId]);

  if(error?.menuEmpty){
    return <ViewError message={'Home page not found'} hideButton />
  }

  return <PageViewer noContainer data={data} />;
};
