import {
  faBars,
  faCopy,
  IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackLink } from "@kgui/core";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";

const Menus: { label: string; key: string; icon: IconDefinition }[] = [
  { label: "Page", key: "page", icon: faCopy },
  { label: "Menu", key: "menu", icon: faBars },
];

const NavLink = styled(Link)({
  color: "inherit",
  textDecoration: "none",
});

export const MainSidebar = ({ tab }: { tab?: string }) => {
  const { id } = useParams();

  return (
    <>
      <BackLink to="/" divider />
      <List>
        {Menus.map(({ label, key, icon }) => (
          <NavLink to={`/m/${id}/${key}`} key={key}>
            <ListItemButton divider selected={tab === key}>
              <ListItemIcon>
                <FontAwesomeIcon icon={icon} />
              </ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </NavLink>
        ))}
      </List>
    </>
  );
};
