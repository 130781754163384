import { PageDocument, PageEdit, useCore } from "@kgui/core";
import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { WebController } from "../../controllers/web.fb";

export const PagePageEdit = () => {
  const { user, t } = useCore();
  const { id, pageId } = useParams();
  const [data, setData] = useState<PageDocument>({});

  const handleSave = async () => {
    if (user.data && id) {
      const control = new WebController(user.data);
      if (pageId) {
        return await control.page.update(pageId, data);
      } else {
        return await control.page.add(id, data);
      }
    }
    return false;
  };

  useEffect(() => {
    if (user.loading === false && user.data && pageId) {
      const control = new WebController(user.data);
      control.page.get(pageId).then((doc) => {
        if (doc !== null) {
          setData(doc);
        }
      });
    }
  }, [user, pageId]);

  return (
    <PageEdit
      breadcrumbs={[
        {
          label: "Home",
          component: (
            <Link fontWeight={"bold"} href="https://mek.network">
              {t("Home")}
            </Link>
          ),
        },
        {
          label: "Website",
          to: "/",
        },
        {
          label: "Page",
          to: `/m/${id}`,
        },
        {
          label: data.title || "No Title",
        },
      ]}
      back={`/m/${id}/`}
      data={data}
      setData={setData}
      show={[
        "feature",
        "heading",
        "title",
        "image",
        "paragraph",
        "visibility",
        "video",
      ]}
      onSave={handleSave}
    />
  );
};
