import { Container, MainContainer } from "@kgui/core";
import { MainSidebar } from "../../components/main.sidebar";
import { ReactNode } from "react";

export const PageManage = ({
  children,
  tab = "post",
}: {
  children?: ReactNode;
  tab?: string;
}) => {
  return (
    <MainContainer signInOnly sidebar={<MainSidebar tab={tab} />}>
      <Container maxWidth="md">{children}</Container>
    </MainContainer>
  );
};
