import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, PageDocument, PageViewer } from "@kgui/core";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebController } from "../../controllers/web.fb";

export const PageViewPage = () => {
  const { webId, pageId } = useParams();
  const [data, setData] = useState<PageDocument>({});
  const [error, setError] = useState<{ [key: string]: boolean }>({
    notFound: false,
  });
  const nav = useNavigate();

  useEffect(() => {
    if (pageId) {
      const control = new WebController(null);
      control.view
        .page(pageId)
        .then((data) => setData(data))
        .catch((err) => {
          if (err.message === "page not found") {
            setError((s) => ({ ...s, notFound: true }));
          }
        });
    }
  }, [pageId]);

  if (error?.notFound) {
    return (
      <Container maxWidth="post">
        <Box textAlign={"center"} py={6}>
          <FontAwesomeIcon icon={["fad", "cactus"]} size="10x" color="green" />
          <Typography
            mt={3}
            variant="h3"
            align="center"
            color="textSecondary"
            paragraph
          >
            Page Not Found
          </Typography>
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={["fad", "chevron-left"]} />}
            onClick={() => nav(`/v/${webId}`)}
          >
            Home
          </Button>
        </Box>
      </Container>
    );
  }
  return (
    <PageViewer
      noContainer
      breadcrumbs={[
        { label: "Home", to: `/v/${webId}` },
        { label: data?.title || "No Title" },
      ]}
      data={data}
    />
  );
};
