import {
  ContentHeader,
  DialogRemove,
  KuiActionIcon,
  KuiButton,
  useAlerts,
  useCore,
} from "@kgui/core";
import {
  Divider,
  IconButton,
  IconButtonProps,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { arrayMoveMutable as arrayMove } from "array-move";
import { DialogAdd } from "./dialog";
import { PageTypes, WebController } from "../../controllers/web.fb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const ActionIcon = ({
  icon,
  ...props
}: IconButtonProps & { icon: IconProp }) => (
  <IconButton size="small" {...props}>
    <FontAwesomeIcon size="xs" icon={icon} />
  </IconButton>
);

export const PageMenu = () => {
  const { t, user } = useCore();
  const { id } = useParams();
  const { addAlert } = useAlerts();
  const [menu, setMenu] = useState<string[]>([]);
  const [state, setState] = useState<{
    loading: boolean;
    docs: { [id: string]: PageTypes };
    remove: string;
  }>({
    loading: true,
    docs: {},
    remove: "",
  });
  const [control, setControl] = useState<WebController | undefined>();

  const handleAdd = async (pageId: string) => {
    if (menu.includes(pageId)) {
      addAlert({ label: "Cannot add duplicate page", severity: "error" });
    } else if (control && id) {
      await control.menu.update(id, menu.concat(pageId));
    }
  };
  const handleSortEnd = async (oldIndex: number, newIndex: number) => {
    if (control && id) {
      arrayMove(menu, oldIndex, newIndex);
      await control.menu.update(id, menu);
    }
  };
  const handleRemove = async () => {
    if (control && id && state.remove) {
      await control.menu.update(
        id,
        menu.filter((id) => id !== state.remove)
      );
      setState((s) => ({ ...s, remove: "" }));
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data && id) {
      const control = new WebController(user.data);
      setControl(control);
      const UnsubscribePage = control.page.watch(id, (docs) => {
        const newDocs = Object.assign(
          {},
          ...docs.map((doc) => ({ [doc.id]: doc }))
        );
        setState((s) => ({ ...s, loading: false, docs: newDocs }));
      });
      const UnsubscribeMenu = control.menu.watch(id, (menu) => setMenu(menu));
      return () => {
        UnsubscribePage();
        UnsubscribeMenu();
      };
    }
  }, [user, id]);

  return (
    <div>
      <ContentHeader
        label="Menu"
        breadcrumbs={[
          {
            label: "Home",
            component: (
              <Link fontWeight={"bold"} href="https://mek.network">
                {t("Home")}
              </Link>
            ),
          },
          { label: "Website", to: "/" },
          { label: "Menu" },
        ]}
        actions={
          <DialogAdd onConfirm={handleAdd}>
            <KuiButton tx="add" />
          </DialogAdd>
        }
      />
      <List>
        <Divider />
        {menu.map((doc, index, docs) => (
          <ListItem divider key={doc}>
            <ListItemText
              primary={state?.docs[doc]?.title || "Page has removed"}
              secondary={
                !Boolean(state?.docs[doc])
                  ? "please remove this menu"
                  : undefined
              }
              secondaryTypographyProps={{ variant: "caption" }}
            />
            <ListItemSecondaryAction>
              {index > 0 && (
                <ActionIcon
                  icon={["fad", "arrow-up"]}
                  onClick={() => handleSortEnd(index, index - 1)}
                />
              )}
              {index < docs.length - 1 && (
                <ActionIcon
                  icon={["fad", "arrow-down"]}
                  onClick={() => handleSortEnd(index, index + 1)}
                />
              )}
              <KuiActionIcon
                tx="remove"
                onClick={() => setState((s) => ({ ...s, remove: doc }))}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {menu.length === 0 && (
          <ListItem divider>
            <ListItemText secondary="No Menu" />
          </ListItem>
        )}
      </List>
      <DialogRemove
        open={Boolean(state.remove)}
        onClose={() => setState((s) => ({ ...s, remove: "" }))}
        onConfirm={handleRemove}
      />
    </div>
  );
};
