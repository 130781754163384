import { cleanObject, PageDocument } from "@kgui/core";
import { User } from "firebase/auth";
import {
  Firestore,
  getFirestore,
  onSnapshot,
  collection,
  QueryDocumentSnapshot,
  DocumentData,
  doc,
  deleteDoc,
  addDoc,
  serverTimestamp,
  Timestamp,
  query,
  where,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  Unsubscribe,
} from "firebase/firestore";
import { app } from "./firebase";

export interface WebDocument {
  id: string;
  title: string;
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  user: User["uid"];
  homepage?: {
    from: string;
    id: string;
  };
}
export interface WebDocumentOptions {
  id?: string;
  title?: string;
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  user?: User["uid"];
  homepage?: {
    from: string;
    id: string;
  };
}

export interface PostDocument extends PageDocument {
  id: string;
}

export interface CatDocument {
  id: string;
  label: string;
  type: "category";
  user: string;
  items?: string[];
}

export interface PageTypes extends PageDocument {
  id: string;
  datecreate?: Timestamp;
  datemodified?: Timestamp;
  user?: User["uid"];
}

export interface MenuTypes {
  id: string;
  title: string;
}

export class WebController {
  private prefix: string = `${process.env.REACT_APP_PREFIX}`;
  // private app: FirebaseApp = app;
  private db: Firestore = getFirestore(app);
  // private auth: Auth = getAuth(app);

  constructor(private user: User | null) {}

  private toDoc(doc: QueryDocumentSnapshot<DocumentData>) {
    return Object.assign({}, doc.data({ serverTimestamps: "estimate" }), {
      id: doc.id,
    });
  }
  private collection = (path: string, ...pathSegments: string[]) =>
    collection(this.db, "clients", this.prefix, path, ...pathSegments);
  private doc = (path: string, ...pathSegments: string[]) =>
    doc(this.db, "clients", this.prefix, path, ...pathSegments);

  web = {
    getHome: async (
      webId: string
    ): Promise<{ web?: WebDocument; post?: PostDocument }> => {
      const web = (
        await getDoc(this.doc("websites", webId))
      ).data() as WebDocument;
      if (web?.homepage?.id) {
        if (web.homepage.from === "post") {
          const post = (
            await getDoc(this.doc("websites", web.homepage.id))
          ).data() as PostDocument;
          return { web, post };
        }
      }
      return { web };
    },
    watchOne: (
      webId: string,
      callback: (data: WebDocument) => void
    ): (() => void) => {
      return onSnapshot(this.doc("websites", webId), (snapshot) => {
        const doc = snapshot.data() as WebDocument;
        callback(doc);
      });
    },
    watchList: (callback: (docs: WebDocument[]) => void) => {
      return onSnapshot(
        query(
          this.collection("websites"),
          where("user", "==", this?.user?.uid),
          where("type", "==", "website")
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(
            (doc) => this.toDoc(doc) as WebDocument
          );
          callback(docs);
        }
      );
    },
    add: async (title: string): Promise<boolean> => {
      if (!this.user) {
        throw new Error("Please sign in");
      } else {
        const webId = title.toLocaleLowerCase().replace(/[^a-z0-9ก-๙]/, "_")
        await setDoc(this.doc("websites", webId), {
          title,
          type: "website",
          user: this.user.uid,
          datecreate: serverTimestamp(),
          datemodified: serverTimestamp(),
        });
        return Boolean(webId);
      }
    },
    update: async (webId: string, options: WebDocumentOptions) => {
      return await setDoc(this.doc("websites", webId), options, {
        merge: true,
      });
    },
    removeOne: async (id: string) => await deleteDoc(this.doc("websites", id)),
  };

  cat = {
    watch: (webId: string, callback: (cats: CatDocument[]) => void) => {
      return onSnapshot(
        query(
          this.collection("websites"),
          where("parent", "==", webId),
          where("type", "==", "category")
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(this.toDoc) as CatDocument[];
          callback(docs);
        }
      );
    },
    add: async (webId: string, value: string) => {
      if (this?.user?.uid) {
        return addDoc(this.collection("websites"), {
          label: value,
          parent: webId,
          type: "category",
          user: this.user.uid,
        });
      } else {
        throw new Error("Please sign in");
      }
    },
    update: async (
      catId: number | string,
      { field, value }: { field: string; value: any }
    ) => {
      return await updateDoc(this.doc("websites", `${catId}`), {
        [field]: value,
      });
    },
    remove: async (ids: string[]) => {
      return await Promise.all(
        ids.map(async (id) => await deleteDoc(this.doc("websites", id)))
      );
    },
  };

  post = {
    view: async (postId: string): Promise<PostDocument | null> => {
      const post = await getDoc(this.doc("websites", postId));
      if (post.exists()) {
        return this.toDoc(post) as PostDocument;
      } else {
        return null;
      }
    },
    getOne: async (postId: string): Promise<PostDocument | null> => {
      if (this.user) {
        const post = await getDoc(this.doc("websites", postId));
        if (post.exists()) {
          return this.toDoc(post) as PostDocument;
        } else {
          return null;
        }
      } else {
        throw new Error("please sign in");
      }
    },
    watchList: (webId: string, callback: (docs: PostDocument[]) => void) => {
      return onSnapshot(
        query(
          this.collection("websites"),
          where("user", "==", this?.user?.uid),
          where("type", "==", "post"),
          where("parent", "==", webId)
        ),
        (snapshot) => {
          const docs = snapshot.docs.map(this.toDoc) as PostDocument[];
          callback(docs);
        }
      );
    },
    getList: async <T extends PageDocument>(webId: string): Promise<T[]> => {
      const snapshot = await getDocs(
        query(
          this.collection("websites"),
          where("user", "==", this?.user?.uid),
          where("parent", "==", webId)
        )
      );
      const docs = snapshot.docs.map(this.toDoc) as unknown as T[];
      return docs;
    },
    getOutside: async (): Promise<any[]> => {
      const snapshot = await getDocs(
        query(
          collection(this.db, "documents"),
          where("user", "==", this.user?.uid),
          where("type", "==", "post")
        )
      );
      const docs = snapshot.docs.map(this.toDoc);
      return docs;
    },
    duplicate: async (webId: string, postId: string): Promise<boolean> => {
      const docs = (
        await getDocs(
          query(
            this.collection("clients"),
            where("parent", "==", webId),
            where("ref", "==", postId)
          )
        )
      ).docs.map(this.toDoc);
      if (docs.length > 0) {
        return false;
      } else {
        console.log(postId);
        const post = await getDoc(doc(this.db, "documents", postId));
        console.log(post.data());
        return true;
      }
    },
    create: async (
      webId: string,
      data: PageDocument,
      onRejected?: (error: Error) => void
    ): Promise<boolean> => {
      if (this.user) {
        const newData = Object.assign(
          {},
          {
            datecreate: serverTimestamp(),
            datemodified: serverTimestamp(),
            user: this.user.uid,
            parent: webId,
          },
          data
        );
        const post = await addDoc(this.collection("websites"), newData);
        return Boolean(post.id);
      } else {
        const err = new Error("please sign in");
        onRejected?.(err);
        throw err;
      }
    },
    update: async (postId: string, data: PageDocument) => {
      if (this.user) {
        const newData = Object.assign(
          {},
          { datemodified: serverTimestamp() },
          data
        );
        return await setDoc(
          this.doc("websites", postId),
          cleanObject(newData),
          {
            merge: true,
          }
        ).catch((err) => {
          throw new Error(err.message);
        });
      } else {
        throw new Error("Please sign in");
      }
    },
    remove: async (postId: string) =>
      await deleteDoc(this.doc("websites", postId)),
  };

  page = {
    get: async (pageId: string): Promise<PageTypes | null> => {
      const snapshot = await getDoc(this.doc("websites", pageId));
      const doc = { ...snapshot.data(), id: snapshot.id } as PageTypes;
      return snapshot.exists() ? doc : null;
    },
    watch: (webId: string, callback: (docs: PageTypes[]) => void) => {
      if (this.user) {
        return onSnapshot(
          query(
            this.collection("websites"),
            where("parent", "==", webId),
            where("type", "==", "page")
          ),
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) as PageTypes[];
            callback(docs);
          }
        );
      } else {
        return () => {};
      }
    },
    add: async (webId: string, data: PageDocument) => {
      if (this.user) {
        const result = await addDoc(this.collection("websites"), {
          ...cleanObject(data),
          datemodified: serverTimestamp(),
          datecreate: serverTimestamp(),
          user: this.user.uid,
          type: "page",
          parent: webId,
        });
        return Boolean(result.id);
      } else {
        return false;
      }
    },
    update: async (pageId: string, data: PageDocument) => {
      await updateDoc(this.doc("websites", pageId), {
        ...cleanObject(data),
        datemodified: serverTimestamp(),
      });
      return true;
    },
    remove: (pageId: string) => {
      return deleteDoc(this.doc("websites", pageId));
    },
  };

  menu = {
    watch: (webId: string, callback: (menu: string[]) => void): Unsubscribe => {
      return onSnapshot(this.doc("websites", webId), (snapshot) => {
        const doc = snapshot.data();
        callback(doc?.menu || []);
      });
    },
    update: async (webId: string, menu: string[]): Promise<void> => {
      await updateDoc(this.doc("websites", webId), {
        menu,
        datemodified: serverTimestamp(),
      });
    },
  };

  view = {
    menu: async (webId: string): Promise<MenuTypes[]> => {
      const menu: string[] =
        (await getDoc(this.doc("websites", webId))).data()?.menu || [];
      const menuData = (await Promise.all(
        menu.map(async (id) => {
          const data = (await getDoc(this.doc("websites", id))).data();
          return { ...data, id };
        })
      )) as MenuTypes[];
      return menuData.filter((item) => item?.title);
    },
    home: async (webId:string):Promise<PageTypes> => {
      const menu = await this.view.menu(webId);
      if(menu.length > 0){
        const doc = await getDoc(this.doc("websites",menu[0].id));
        if(doc.exists()){
          return doc.data() as PageTypes
        } else {
          throw new Error('page not found')
        }
      } else {
        throw new Error('menu empty')
      }
    }
    ,page: async (pageId:string):Promise<PageTypes> => {
      const snapshot = (await getDoc(this.doc("websites", pageId)));
      if(snapshot.exists()){
        return snapshot.data() as PageTypes
      } else {
        throw new Error('page not found')
      }
    }
  };
}
