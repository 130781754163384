import {
  Container,
  ContentHeader,
  DataGrid,
  DialogPrompt,
  DialogRemove,
  KuiButton,
  MainContainer,
  useAlerts,
  useCore,
} from "@kgui/core";
import { useEffect, useState } from "react";
import { IconButton, Link as MLink } from "@mui/material";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { WebController, WebDocument } from "../../controllers/web.fb";
import moment from "moment";

export const PageHome = () => {
  const { user, t } = useCore();
  const { addAlert } = useAlerts();
  const [control, setControl] = useState<WebController>(
    new WebController(null)
  );
  const [state, setState] = useState<{
    loading: boolean;
    docs: WebDocument[];
    remove: string | null;
  }>({
    loading: true,
    docs: [],
    remove: null,
  });

  const handleAdd = async (title: string) => await control.web.add(title);
  const handleRemove = (id: string | null) => () =>
    setState((s) => ({ ...s, remove: id }));
  const handleRemoveConfirm = async () => {
    if (state.remove) {
      await control.web.removeOne(state.remove).catch((err) => {
        addAlert({ label: err.message, severity: "error" });
      });
      addAlert({ label: "Delete Success" });
      setState((s) => ({ ...s, remove: null }));
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data) {
      const control = new WebController(user.data);
      setControl(control);
      return control.web.watchList((docs) =>
        setState((s) => ({ ...s, docs, loading: false }))
      );
    } else {
      return () => {
        setState((s) => ({ ...s, docs: [], loading: false }));
      };
    }
  }, [user]);

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("Website")}
          breadcrumbs={[{ label: t("Home") }]}
          actions={
            <DialogPrompt
              title="Add Website"
              label={t("Label")}
              onConfirm={handleAdd}
              clearAfterConfirm
            >
              <KuiButton tx="add" />
            </DialogPrompt>
          }
        />
        <DataGrid
          loading={state.loading}
          rows={state.docs}
          columns={[
            {
              field: "action",
              headerName: " ",
              sortable: false,
              filterable: false,
              align: "center",
              width: 64,
              renderCell: ({ row }: { row: WebDocument }) => (
                <>
                  <MLink component={Link} to={`/m/${row.id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </MLink>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={handleRemove(row.id)}
                  >
                    <FontAwesomeIcon size="xs" icon={faTrash} />
                  </IconButton>
                </>
              ),
            },
            {
              field: "title",
              headerName: t("Title"),
              renderCell: ({ row }: { row: WebDocument }) => (
                <MLink component={Link} to={`/v/${row.id}`} target="_blank">
                  {row.title}
                </MLink>
              ),
              width: 240,
            },
            {
              field: "date",
              headerName: t("Date"),
              valueGetter: ({ row }) =>
                (row.datemodified || row.datecreate)?.toMillis() || Date.now(),
              width: 240,
              renderCell: ({ value }) => moment(value).format("L LT"),
            },
          ]}
          disableSelectionOnClick
        />
      </Container>
      <DialogRemove
        open={Boolean(state.remove)}
        onClose={handleRemove(null)}
        onConfirm={handleRemoveConfirm}
      />
    </MainContainer>
  );
};
