import {
  ContentHeader,
  DataGrid,
  DialogRemove,
  KuiActionIcon,
  KuiButton,
  PageDocument,
  useCore,
} from "@kgui/core";
import { Avatar, Link } from "@mui/material";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WebController } from "../../controllers/web.fb";

export const PagePage = () => {
  const { id } = useParams();
  const { user, t } = useCore();
  const [state, setState] = useState<{
    loading: boolean;
    docs: PageDocument[];
    remove: string | null;
  }>({
    loading: true,
    docs: [],
    remove: null,
  });
  const nav = useNavigate();

  const handleRemove = async () => {
    if (user.data && state.remove) {
      const control = new WebController(user.data);
      await control.page.remove(state.remove);
      setState((s) => ({ ...s, remove: null }));
    }
  };

  useEffect(() => {
    if (user.loading === false && user.data && id) {
      const control = new WebController(user.data);
      return control.page.watch(id, (docs) =>
        setState((s) => ({ ...s, docs, loading: false }))
      );
    } else {
      return () => {};
    }
  }, [user, id]);

  return (
    <div>
      <ContentHeader
        label="Page"
        breadcrumbs={[
          {
            label: "Home",
            component: (
              <Link fontWeight={"bold"} href="https://mek.network">
                {t("Home")}
              </Link>
            ),
          },
          { label: "Website", to: "/" },
          { label: "Page" },
        ]}
        actions={
          <KuiButton tx="add" onClick={() => nav(`/m/${id}/page/create`)} />
        }
      />
      <DataGrid
        rows={state.docs}
        columns={[
          {
            field: "action",
            headerName: " ",
            width: 64,
            renderCell: ({ row }) => {
              return (
                <Fragment>
                  <KuiActionIcon
                    tx="edit"
                    onClick={() => nav(`/m/${id}/page/edit/${row.id}`)}
                  />
                  <KuiActionIcon
                    tx="remove"
                    onClick={() => setState((s) => ({ ...s, remove: row.id }))}
                  />
                </Fragment>
              );
            },
            align: "center",
          },
          {
            field: "feature",
            headerName: " ",
            width: 64,
            renderCell: ({ value }) => {
              return (
                <Avatar
                  src={`https://s1.phra.in:8086/file/id/${value?.image?._id}`}
                  imgProps={{
                    style: {
                      objectPosition: value.pos
                        ? `${value.pos.left} ${value.pos.top}`
                        : undefined,
                    },
                  }}
                />
              );
            },
          },
          { field: "title", headerName: t("Title"), width: 240 },
          {
            field: "datemodified",
            headerName: t("Date"),
            valueGetter: ({ value }) => value?.toMillis?.() || Date.now(),
            renderCell: ({ value }) => moment(value).format("L LT"),
            width: 240,
          },
        ]}
        disableSelectionOnClick
      />
      <DialogRemove
        open={Boolean(state.remove)}
        onClose={() => setState((s) => ({ ...s, remove: null }))}
        onConfirm={handleRemove}
      />
    </div>
  );
};
