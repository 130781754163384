import { KuiButton, useCore } from "@kgui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import * as React from "react";
import { useParams } from "react-router-dom";
import { PageTypes, WebController } from "../../controllers/web.fb";

interface DialogAddProps {
  children?: React.ReactElement;
  onConfirm: (id: string) => void;
}
export const DialogAdd = (props: DialogAddProps) => {
  const { t, user } = useCore();
  const { id } = useParams();
  const [open, setOpen] = React.useState<boolean>(false);
  const [pages, setPages] = React.useState<PageTypes[]>([]);
  const [value, setValue] = React.useState<string>("");

  const handleOpen = (open: boolean) => () => setOpen(open);
  const handleConfirm = () => {
    props.onConfirm(value);
    setValue("");
    setOpen(false);
  };

  React.useEffect(() => {
    if (user.loading === false && user.data && open && id) {
      const control = new WebController(user.data);
      return control.page.watch(id, (docs) => {
        setPages(docs);
      });
    }
  }, [user, id, open]);

  return (
    <React.Fragment>
      {props.children &&
        React.cloneElement(props.children, {
          onClick: handleOpen(true),
        })}
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleOpen(false)}>
        <DialogTitle>{t("Select?", { name: " Page" })}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>Page</InputLabel>
            <Select
              label="Page"
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
            >
              <MenuItem value="" disabled>
                -- {t("Select?", { name: " Page" })} --
              </MenuItem>
              {pages.map((page) => (
                <MenuItem value={page.id} key={page.id}>
                  {page.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <KuiButton
            tx="confirm"
            disabled={!Boolean(value)}
            onClick={handleConfirm}
          />
          <KuiButton tx="close" />
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
