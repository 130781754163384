import "./App.css";
import { CoreProvider } from "@kgui/core";
import { firebaseConfig } from "./controllers/firebase";
import { BrowserRouter, Routes, Route, Link, To } from "react-router-dom";
import logo from "./logo.svg";
import { PageHome } from "./pages/home";
import { PageManage } from "./pages/manage";
import { initializeApp } from "@kgui/core";
import { PagePage } from "./pages/page";
import { PagePageEdit } from "./pages/page.edit";
import { PageMenu } from "./pages/menu";
import { ViewWrapper } from "./components/view.wrapper";
import { PageViewHome } from "./pages/view.home";
import { PageViewPage } from "./pages/view.page";
import { ReactNode, useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const app = initializeApp(firebaseConfig);
interface menuTypes {
  icon?: IconProp | any;
  label?: ReactNode;
  to?: To;
  href?: string;
  type: "a" | "Link" | "divider";
}
export const getMenu = (callback: (menu: menuTypes[]) => void) => {
  const rt = getDatabase(app);
  return onValue(ref(rt, "menus"), (snapshot) => {
    callback(snapshot.val());
  });
};

function App() {
  const [menu, setMenu] = useState<menuTypes[]>([]);

  useEffect(() => {
    return getMenu((menu) => setMenu(menu));
  }, []);

  return (
    <CoreProvider
      firebaseApp={app}
      Link={Link}
      sitename={``}
      logo={logo}
      appMenu={menu}
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/v/:webId/*"
            element={
              <ViewWrapper>
                <Routes>
                  <Route path="/page/:pageId" element={<PageViewPage />} />
                  <Route path="/" element={<PageViewHome />} />
                </Routes>
              </ViewWrapper>
            }
          />
          <Route
            path="/m/:id/*"
            element={
              <Routes>
                <Route
                  path="/menu"
                  element={
                    <PageManage tab="menu">
                      <PageMenu />
                    </PageManage>
                  }
                />
                <Route path="/page/edit/:pageId" element={<PagePageEdit />} />
                <Route path="/page/create" element={<PagePageEdit />} />
                <Route
                  path="/page"
                  element={
                    <PageManage tab="page">
                      <PagePage />
                    </PageManage>
                  }
                />
                <Route
                  path="/"
                  element={
                    <PageManage tab="page">
                      <PagePage />
                    </PageManage>
                  }
                />
              </Routes>
            }
          />
          <Route path="/" element={<PageHome />} />
        </Routes>
      </BrowserRouter>
    </CoreProvider>
  );
}

export default App;
